import React from 'react';
import Layout from '../components/Layout';

const SignLanguage = () => {
  return (
    <Layout pageTitle="Erklärung zur Barrierefreiheit" pageUrl="erklaerung-zur-barrierefreiheit">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Erklärung zur Barrierefreiheit
        </h1>
        <div className="prose lg:prose-xl max-w-full">
          <p><strong>Diese Erklärung zur Barrierefreiheit gilt für die Internetseite <a href="https://www.wieklickstdu.de" target="_blank" rel="nofollow noopener noreferrer">www.wieklickstdu.de<span className="sr-only"> (öffnet ein neues Fenster)</span></a>.</strong></p>

          <h2>Barrierefreiheit dieser Internetseite</h2>
          <p>
            Das Presse- und Informationsamt der Bundesregierung ist bemüht, die Internetseite
            <a href="https://www.wieklickstdu.de/" target="_blank" rel="nofollow noopener noreferrer">www.wieklickstdu.de<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
            barrierefrei zugänglich zu machen. Rechtsgrundlage sind das Behindertengleichstellungsgesetz (BGG) und die Barrierefreie-Informationstechnik-Verordnung (BITV 2.0) in ihrer jeweils gültigen Fassung.
          </p>
          <p>
            Wir haben diese Internetseite vom unabhängigen BITV-Test-Prüfverbund testen lassen. Grundlage für den BITV-Test ist die Barrierefreie-Informationstechnik-Verordnung (BITV 2.0) vom 21. Mai 2019. Der Test bezieht also die neuen Anforderungen der WCAG 2.1 mit ein. Der WCAG-Test basiert auf den Web Content Accessibility Guidelines 2.1 vom 5. Juni 2018. Ausführliche Informationen zu den Prüfverfahren: www.bitvtest.de.
          </p>
          <p>
            Eine Webseite gilt als BITV-konform, wenn sie alle Anforderungen der BITV 2.0 erfüllt. Eine Webseite gilt als WCAG-konform, wenn sie alle Erfolgskriterien von Stufe AA der WCAG 2.1 erfüllt. Alle für die Prüfung ausgewählten Seiten wurden als BITV-konform und als WCAG-konform bewertet.
          </p>
          <p>
            Gemäß WCAG kann Konformität nur für einzelne Seiten ermittelt werden. Zitat der Prüfstelle: „Um eine Aussage zur Konformität des gesamten Webauftritts zu machen, müssten also alle der Website zugehörigen einzelnen Webseiten geprüft werden. Dies ist für umfangreiche Websites nicht praktikabel. Die Prüfung einer repräsentativen Seitenauswahl, d.h. einer Seitenauswahl, die möglichst alle potentiellen Barrieren erfasst, lässt jedoch auf die Zugänglichkeit des Webauftritts als Ganzen schließen.“
          </p>
          <p>Diese Erklärung wurde am 26. Februar 2021 erstellt. </p>

          <h2>Feedback und Kontakt</h2>
          <p>
            Sind Ihnen Mängel beim barrierefreien Zugang zu Inhalten von <a href="https://www.wieklickstdu.de/" target="_blank" rel="nofollow noopener noreferrer">www.wieklickstdu.de<span className="sr-only"> (öffnet ein neues Fenster)</span></a> aufgefallen?
            Oder haben Sie Fragen zum Thema Barrierefreiheit? Dann können Sie sich gerne bei uns melden und eine E-Mail an <a href="mailto:internetpost@bundesregierung.de">internetpost@bundesregierung.de</a> schreiben.
          </p>
          <p>Sie können uns auch per Post oder telefonisch kontaktieren:</p>
          <p>
            Presse- und Informationsamt der Bundesregierung<br />
            Redaktion Digital/Barrierefreiheit<br />
            Dorotheenstraße 84<br />
            10117 Berlin<br />
            Telefon: 030 18272-0
          </p>

          <h2>Schlichtungsverfahren</h2>
          <p>
            Beim Beauftragten der Bundesregierung für die Belange von Menschen mit Behinderungen gibt es eine Schlichtungsstelle gemäß § 16 BGG. Die Schlichtungsstelle hat die Aufgabe, Konflikte zwischen Menschen mit Behinderungen und öffentlichen Stellen des Bundes zu lösen.
          </p>
          <p>
            Sie können die Schlichtungsstelle einschalten, wenn Sie mit den Antworten aus der oben genannten Kontaktmöglichkeit nicht zufrieden sind. Dabei geht es nicht darum, Gewinner oder Verlierer zu finden. Vielmehr ist es das Ziel, mit Hilfe der Schlichtungsstelle gemeinsam und außergerichtlich eine Lösung für ein Problem zu finden.
          </p>
          <p>Das Schlichtungsverfahren ist kostenlos. Sie brauchen auch keinen Rechtsbeistand.</p>
          <p>
            Auf der <a href="https://www.behindertenbeauftragter.de/DE/SchlichtungsstelleBGG/SchlichtungsstelleBGG_node.html" target="_blank" rel="nofollow noopener noreferrer">Internetseite der Schlichtungsstelle<span className="sr-only"> (öffnet ein neues Fenster)</span></a> finden
            Sie alle Informationen zum Schlichtungsverfahren. Dort können Sie
            nachlesen, wie ein Schlichtungsverfahren abläuft und wie Sie den Antrag
            auf Schlichtung stellen. Sie können den Antrag auch in <a href="https://www.behindertenbeauftragter.de/DE/SchlichtungsstelleBGG/LS/LS_node.html" target="_blank" rel="nofollow noopener noreferrer">Leichter Sprache<span className="sr-only"> (öffnet ein neues Fenster)</span></a> oder
            in <a href="https://www.behindertenbeauftragter.de/DE/SchlichtungsstelleBGG/GBS/GBS_node.html" target="_blank" rel="nofollow noopener noreferrer">Deutscher Gebärdensprache<span className="sr-only"> (öffnet ein neues Fenster)</span></a> stellen.
          </p>
          <p>Sie erreichen die Schlichtungsstelle unter folgender Adresse:</p>
          <p>
            Schlichtungsstelle nach dem Behindertengleichstellungsgesetz<br />
            bei dem Beauftragten der Bundesregierung für die Belange von Menschen
            mit Behinderungen <br />
            Mauerstraße 53 <br />
            10117 Berlin
          </p>
          <p>
            Telefon: 030 18527-2805<br />
            Fax: 030 18527-2901<br />
            E-Mail: <a href="mailto:info@schlichtungsstelle-bgg.de">info@schlichtungsstelle-bgg.de</a><br />
            Internet: <a href="https://www.behindertenbeauftragter.de/DE/SchlichtungsstelleBGG/SchlichtungsstelleBGG_node.html" target="_blank" rel="nofollow noopener noreferrer">www.schlichtungsstelle-bgg.de<span className="sr-only"> (öffnet ein neues Fenster)</span></a>
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default SignLanguage;
